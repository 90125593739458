body {
    margin: 0;
    font-family: "Kanit", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-layout-content {
    padding: 16px 24px;
}

.Toastify__toast-body {
    font-family: "Kanit", sans-serif !important;
}
